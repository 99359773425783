<template>
  <div>
    <button @click="showModal" style="cursor: pointer" id="cancelMeeting">
      Cancel this Meeting
    </button>
    <b-modal hide-footer body-class="p-0" ref="cancel-meeting-modal">
      <template v-slot:modal-header>
        <div class="header">
          <span>Are you sure you want to cancel this meeting?</span>
        </div>
      </template>
      <form novalidate @submit.prevent="validateBeforeSubmit">
        <div class="date">
          <p class="dayMonth">
            {{ moment(day).format("dddd DD MMMM") }}
          </p>
          <p class="confirmedTime">
            {{ timeData.start.slice(0, 5) }} - {{ timeData.end.slice(0, 5) }}
          </p>
          <div v-if="timeData">
            <h5 class="statusText">Status</h5>
            <p class="statusType" v-if="timeData.slot_type === 'meeting'">
              confirmed
            </p>
            <p class="statusType" v-if="timeData.slot_type === 'pending'">
              pending
            </p>
          </div>
        </div>
        <div class="editMultyUserInfo">
          <div
            v-for="invitation in timeData.invitation_request_details"
            :key="invitation.id"
            class="meeting-members"
          >
            <div class="imgBox p-0">
              <img
                width="100%"
                height="100%"
                :src="invitation.requested_to_details.profile_picture"
                alt=""
                :class="!invitation.approved && 'pending-user-img'"
              />
              <i
                v-if="!invitation.approved && !invitation.is_proceeded"
                class="far fa-clock pending-user-icon"
              />
              <i
                v-if="!invitation.approved && invitation.is_proceeded"
                class="fas fa-ban declined-user-icon"
              />
            </div>
            <div class="textBox">
              <router-link
                :to="{
                  name: 'userProfilePage',
                  params: { customerId: invitation.requested_to_id },
                }"
              >
                <p class="editUserName">
                  {{ invitation.requested_to_details.user.first_name }}
                  {{ invitation.requested_to_details.user.last_name }}
                </p>
              </router-link>
              <p class="editUserPosition">
                {{ invitation.requested_to_details.job_title }}
              </p>
              <router-link
                :to="{
                  name: 'companyProfile',
                  params: {
                    companyId: invitation.requested_to_details.company_id,
                  },
                }"
              >
                <p class="editUserCompany mb-0">
                  {{ invitation.requested_to_details.company.name }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="article" v-if="timeData.invitation_request_details.length">
          <p class="mt-2 mb-1">
            {{ timeData.invitation_request_details[0].meeting_details.message }}
          </p>
        </div>
        <div class="Location" v-if="timeData.invitation_request_details.length">
          <p class="meetingLocation">Meeting Location:</p>
          <p class="roomMeeting">
            {{
              timeData.invitation_request_details[0].meeting_details.location
            }}
          </p>
        </div>
        <div class="textField">
          <p>
            Please be kind and send a cancellation message to
            <span class="userInfo"
              >{{
                timeData.invitation_request_details[0].requested_to_details.user
                  .first_name
              }}
              {{
                timeData.invitation_request_details[0].requested_to_details.user
                  .last_name
              }}</span
            >
          </p>
          <textarea
            v-model="reason"
            name="Message"
            maxlength="300"
            cols="50"
            rows="5"
            v-validate="{ required: true }"
          >
          </textarea>
          <span class="error" v-if="errors.has('Message')">{{
            errors.first("Message")
          }}</span>
        </div>
        <div class="cancelModalfooter footer">
          <span class="cancelModalNeedsupport">
            <a
              class="privacy-settings-support-text"
              href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"
            >
              Need support? Click here!
            </a>
          </span>
          <button class="confirmButton" @click.prevent="sendReq">
            Cancel This Meeting
          </button>
          <button class="confirmButton" @click="hideModal">Back</button>
        </div>
      </form>
    </b-modal>
    <b-modal ref="congrat-canceled-modal" hide-footer @hidden="hideModal">
      <div class="congrat">
        <p>CONGRATULATIONS!</p>
        <p>Your Meeting request has been successfully canceled.</p>
      </div>
      <div style="height: 7vh; width: 100%; border-top: 1px solid #eee"></div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'CancelMeetingModal',
  props: {
    timeData: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    day: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reason: '',
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      event: 'events/fetchEvent',
    }),
  },
  methods: {
    ...mapActions({
      cancelMeeting: 'meetingPlanner/cancelMeetingInvitation',
    }),
    moment(date) {
      return moment(new Date(date).toUTCString());
    },
    showModal() {
      this.$refs['cancel-meeting-modal'].show();
    },
    async sendReq() {
      try {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
          const cancellationData = {
            reason: this.reason,
            meeting: `${process.env.VUE_APP_API_HOST}/events/meeting/${this.timeData.invitation_request_details[0].meeting_id}/`,
          };
          await this.cancelMeeting(cancellationData).then(() => {
            this.$refs['cancel-meeting-modal'].hide();
            this.$refs['congrat-canceled-modal'].show();
            this.$emit('hideMainModal');
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async validateBeforeSubmit() {
      await this.$validator.validate();
    },
    hideModal() {
      this.$refs['cancel-meeting-modal'].hide();
    },
  },
};
</script>
<style src="./modals.css" scoped></style>
<style scoped src="./cancelMeetingModal.css"></style>
<style src="./StylesForMobile.css" scoped></style>
<style>
#cancelModal .btn {
  font-size: 11px;
  border-radius: 0px;
  background-color: #bd9d5f;
}
#cancelModal .btn-secondary {
  color: #fff;
}
#cancelModal .btn-secondary:active {
  border: 1px solid #bd9d5f !important;
}
#cancelModal .btn-primary {
  background-color: #ffffff;
  color: #bd9d5f;
  border: 1px solid #bd9d5f;
}
#cancelModal .btn-secondary:hover {
  background-color: #ffffff !important;
  color: #bd9d5f !important;
}
#cancelModal .btn-primary:hover {
  background-color: #bd9d5f !important;
  color: #ffffff !important;
}
#cancelMeeting {
  width: 139px;
  font-size: 13px;
  background: unset;
  color: #bd9d5f;
  height: 36px;
  margin: -6px 0px 20px auto;
  border: 1px solid;
  border-radius: 2px;
}
#cancelMeeting:hover {
  background: #bd9d5f;
  color: white;
}
</style>
