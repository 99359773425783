<template>
  <div
    class="editInvitation"
    v-if="
      $lodash.get(
        timeData.invitation_request_details[0],
        'requested_by_id',
        ''
      ) === userInfo.id
    "
  >
    <i @click="modalShow" class="fas fa-pencil-alt editInvitationIcon" />
    <b-modal ref="modal-Show" hide-footer @hidden="resetDurationData(false)">
      <div class="slot-day">
        <h4>
          {{ moment(day).format("dddd DD MMMM") }}
          <button class="changeDateTime" @click="showModal">
            <i class="fas fa-pencil-alt mr-1" v-if="!isMobileScreen" /> Edit
            Date/Time
          </button>
        </h4>
      </div>
      <div class="editMeetingDuration">
        <p class="time">
          {{
            moment(
              new Date(`${day} ${$lodash.get(selectedSlots[0], "start")}`)
            ).format("HH:mm")
          }}
          -
          {{
            moment(
              new Date(
                `${day} ${$lodash.get(
                  selectedSlots[selectedSlots.length - 1],
                  "end"
                )}`
              ).getTime()
            ).format("HH:mm")
          }}
        </p>
        <button
        v-if="value.slot_type === 'pending'"
          @click="editDuration"
          :class="
            showEditDurationDiv ? 'confirmButton-disabled' : 'extendDuration'
          "
        >
          {{ showEditDurationDiv ? "Cancel" : "Edit meeting duration" }}
        </button>
      </div>
      <div
        v-if="showEditDurationDiv && extendableSlots.length"
        class="extend-meeting-section"
      >
        <button

          v-for="(slot, index) in extendableSlots"
          :key="slot.id"
          :class="
            selectedSlotsIDs.includes(slot.id)
              ? 'extend-meeting-button-selected'
              : 'extend-meeting-button'
          "
          :disabled="
            index > 0 &&
            !selectedSlotsIDs.includes(
              $lodash.get(extendableSlots[index - 1], 'id')
            )
          "
          @click="changeMeetingDuration(slot)"
        >
          {{ moment(`${day} ${slot.start}`).format("HH:mm") }} -
          {{
            moment(`${day} ${slot.end}`).format("HH:mm")
          }}
          <div
            v-if="
              (!selectedSlotsIDs.includes(slot.id) && index < 1) ||
              (!selectedSlotsIDs.includes(slot.id) &&
                selectedSlotsIDs.includes(
                  $lodash.get(extendableSlots[index - 1], 'id')
                ))
            "
            class="add"
          >
            ADD
          </div>
          <div
            v-if="
              (selectedSlotsIDs.includes(slot.id) && index < 1) ||
              (selectedSlotsIDs.includes(slot.id) &&
                selectedSlotsIDs.includes(
                  $lodash.get(extendableSlots[index - 1], 'id')
                ))
            "
            class="remove"
          >
            REMOVE
          </div>
        </button>
      </div>
      <div
        v-else-if="showEditDurationDiv && !extendableSlots.length"
        class="extendable-slots-empty"
      >
        Next slot is not free
      </div>
      <div class="editMultyUserInfo">
        <div
          v-for="invitation in timeData.invitation_request_details"
          :key="invitation.id"
          class="meeting-members"
        >
          <div class="imgBox p-0">
            <img
              width="100%"
              height="100%"
              :src="invitation.requested_to_details.profile_picture"
              alt=""
              :class="!invitation.approved && 'pending-user-img'"
            />
            <i
              v-if="!invitation.approved && !invitation.is_proceeded"
              class="far fa-clock pending-user-icon"
            />
            <i
              v-if="!invitation.approved && invitation.is_proceeded"
              class="fas fa-ban declined-user-icon"
            />
          </div>
          <div class="textBox">
            <router-link
              :to="{
                name: 'userProfilePage',
                params: {
                  customerId: invitation.requested_to_id,
                },
              }"
            >
              <p class="editUserName">
                {{ invitation.requested_to_details.user.first_name }} {{ invitation.requested_to_details.user.last_name }}
              </p>
            </router-link>
            <p class="editUserPosition">
              {{ invitation.requested_to_details.job_title }}
            </p>
            <router-link
              :to="{
                name: 'companyProfile',
                params: {
                  companyId: invitation.requested_to_details.company_id,
                },
              }"
            >
              <p class="editUserCompany mb-0">
                {{ invitation.requested_to_details.company.name }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
      <AddEditInvitationMembers
        v-if="
          $lodash.get(
            timeData.invitation_request_details[0],
            'requested_by_id'
          ) === userInfo.id
        "
        @meetingDaysUpdate="updateDataFromAddEditMembers"
        :day="day"
        :eventUrl="eventUrl"
        :timeData="timeData"
        :requestedMeeting="requestedMeeting"
        v-model="timeData"
      />
      <div>

        <div class="messageSection" v-if="requestedMeeting">
          <h3>
            Meeting Request Message
            <button
              v-if="timeData.slot_type === 'pending'"
              :class="
                editMessage
                  ? 'editLocationButtonDisabled'
                  : 'editLocationButton'
              "
              @click="editMessage = !editMessage"
              :disabled="editMessage"
            >
              <i class="fas fa-pencil-alt"></i> Edit
            </button>
          </h3>
          <p v-if="!editMessage">{{ requestedMeeting.invitation_request_details[0].meeting_details.message }}</p>
          <textarea
            v-else
            v-model="meetingMessage"
            type="text"
            name="message"
            rows="5"
            class="editLocationMessage"
            placeholder="Write Meeting Message"
            v-validate="{ required: true }"
          />
          <span class="error" v-if="errors.has('message')">
            {{ errors.first("message") }}
          </span>
        </div>
        <div class="messageSection" v-if="requestedMeeting">
          <h3>
            Meeting Location
            <button
              v-if="timeData.slot_type === 'pending'"
              :class="
                editLocation
                  ? 'editLocationButtonDisabled'
                  : 'editLocationButton'
              "
              @click="editLocation = !editLocation"
              :disabled="editLocation"
            >
              <i class="fas fa-pencil-alt"></i> Edit
            </button>
          </h3>
          <p v-if="!editLocation">{{ requestedMeeting.invitation_request_details[0].meeting_details.location }}</p>
          <input
            v-else
            v-model="meetingLocation"
            type="text"
            name="location"
            class="editLocationMessage"
            placeholder="Write Meeting Location"
            v-validate="{ required: true }"
          />
          <span class="error" v-if="errors.has('location')">
            {{ errors.first("location") }}
          </span>
        </div>
      </div>
      <div class="forStatus">
        <div v-if="timeData">
          <h5 class="statusText">Status</h5>
          <p class="statusType">
            {{ slotTypes[timeData.slot_type] }}
          </p>
        </div>
        <span
          v-if="
            timeData.invitation_request_details.length &&
            timeData.invitation_request_details[0].requested_by_id ===
              userInfo.id
          "
          class="cancel"
        >
          <CancelMeetingModal
            :timeData="timeData"
            :day="day"
            v-model="value"
            @hideMainModal="hideModalsFromChilds"
          />
        </span>
      </div>
      <div class="footer">
        <span class="support">
          <a
            class="privacy-settings-support-text"
            href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"
          >
            Need support? Click here!
          </a>
        </span>
        <button class="confirmButton" @click.prevent="editInvitation">
          Update
        </button>
        <button
          class="cancelButton"
          :class="timeData.slot_type !== 'pending' && 'full-width'"
          @click="hide"
        >
          Cancel
        </button>
      </div>
    </b-modal>
    <div>
      <b-modal
        ref="my-modal"
        hide-footer
        size="lg"
        @hidden="resetDurationData(true)"
      >
        <template v-slot:modal-header>
          <div class="headerClose">
            <p class="userNotAvailable">
              Please select one of the free time-slots listed below.
            </p>
            <button @click="handleClose">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </template>
        <div v-if="!isMobileScreen">
          <b-container class="bv-example-row">
            <b-row>
              <b-col sm="2" class="bCol"><h3>TIME SLOT</h3></b-col>
              <b-col sm class="bCol" v-for="day in days" :key="day">
                <h3>{{ moment(day).format("dddd DD MMM") }}</h3>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="bv-example-row" v-for="time in times" :key="time">
            <b-row>
              <b-col sm="2" class="reqMeeting">
                <h3>
                  <span class="timeMeeting">
                    {{
                      moment(`${day} ${time}`).format("HH:mm")
                    }}
                    -
                    {{
                      moment(
                        new Date(`${day} ${time}`).getTime() + 1800000
                      ).format("HH:mm")
                    }}
                  </span>
                </h3>
              </b-col>
              <b-col
                class="reqMeeting"
                v-for="(timeData, index) in meetings[time]"
                :key="index"
              >
                <div
                  v-if="timeData"
                  :style="
                    timeData.agenda_id &&
                    timeData.availability === availability &&
                    timeData.agenda_details.type !== 'static' &&
                    'display:grid'
                  "
                >
                  <!-- <router-link
                    v-if="
                      timeData &&
                      timeData.agenda_id &&
                      timeData.availability === availability &&
                      timeData.agenda_details.type !== 'static'
                    "
                    :to="{
                      name: 'Agenda',
                      params: {
                        day: days[index],
                        agendaUrl: timeData.agenda,
                      },
                    }"
                    class="agendaType"
                    v-b-popover.hover.top="
                      timeData.agenda_details.start_time.slice(0, 5) +
                      ' - ' +
                      timeData.agenda_details.end_time.slice(0, 5)
                    "
                  >
                    {{ agendaTypes[timeData.agenda_details.type] || "" }}
                  </router-link>
                  <span v-else>{{ null }}</span> -->
                  <div v-if="timeData.availability !== availability">
                    <p id="notAvailableText">
                      {{ timeData.availability }}
                    </p>
                  </div>
                  <div v-if="timeData.availability === availability">
                      <InvitationFromUpdate
                        v-model="meetingDaysMap[time][index]"
                        :day="days[index]"
                        :requestedMeeting="requestedMeeting"
                        @hideMainModal="hideModal"
                      />
                  </div>
                </div>

                <div v-else>{{ null }}</div>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div
          v-else
          class="bv-example-row"
          v-for="(day, i) in days"
          :key="`${day}${i + i}`"
        >
          <b-card no-body>
            <b-card-header header-tag="header" class="p-3" role="tab">
              <div v-b-toggle="`${day}${i}edit`" class="text-center">
                {{ moment(day).format("DD MMMM") }}
                <i class="fas fa-chevron-up" />
                <i class="fas fa-chevron-down" />
              </div>
            </b-card-header>
            <b-collapse
              :id="`${day}${i}edit`"
              accordion="mp-head-accordion"
              role="tabpanel"
            >
              <b-card-body class="p-0">
                <b-row
                  class="bv-example-row m-0"
                  v-for="(dayData, index) in meetingDaysForMobile[day]"
                  :key="dayData.id"
                  :style="
                    dayData.availability !== availability &&
                    'background-color:#b8b8b8'
                  "
                >
                  <div class="reqMeeting">
                    <h3>
                      <span>{{
                        moment(`${day} ${dayData.start}`).format(
                          "HH:mm"
                        )
                      }}</span>
                      -
                      <span>{{
                        moment(`${day} ${dayData.end}`).format(
                          "HH:mm"
                        )
                      }}</span>
                    </h3>
                  </div>
                  <div class="reqMeeting">
                    <div
                      :id="
                        dayData.availability !== availability &&
                        'notAvailableText'
                      "
                    >
                      <!-- <router-link
                        v-if="
                          dayData.agenda_id &&
                          dayData.availability === availability &&
                          dayData.agenda_details.type !== 'static'
                        "
                        :to="{
                          name: 'Agenda',
                          params: {
                            day,
                            agendaUrl: dayData.agenda,
                          },
                        }"
                        class="agendaType"
                        v-b-popover.hover.top="
                          dayData.agenda_details.start_time.slice(0, 5) +
                          ' - ' +
                          dayData.agenda_details.end_time.slice(0, 5)
                        "
                      >
                        {{ agendaTypes[dayData.agenda_details.type] || "" }}
                      </router-link>
                      <span v-else>{{ null }}</span> -->
                      <div v-if="dayData.availability !== availability">
                        <p id="notAvailableText">
                          {{ dayData.availability }}
                        </p>
                      </div>
                      <div v-if="dayData.availability === availability">
                        <p id="reqMeeting" @click="showModal">
                          <InvitationFromUpdate
                            v-model="meetingDaysForMobile[day][index]"
                            :day="day"
                            :requestedMeeting="requestedMeeting"
                            @hideMainModal="hideModal"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import CancelMeetingModal from './CancelMeetingModal.vue';
import InvitationFromUpdate from './InvitationFromUpdate.vue';
import extendMeetingDuration from '../../../../utils/mixins/extendMeetingDuration';
import AddEditInvitationMembers from './AddEditInvitationMembers.vue';

export default {
  components: {
    CancelMeetingModal,
    InvitationFromUpdate,
    AddEditInvitationMembers,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    timeData: {
      type: Object,
      default: () => {},
    },
    day: {
      type: String,
      default: '',
    },
    eventUrl: {
      type: String,
      default: '',
    },
  },
  mixins: [extendMeetingDuration],
  data() {
    return {
      form: {
        message: '',
        location: '',
      },
      meetingPlannerSlots: [],
      invitedCustomers: [],
      congratModal: false,
      error: null,
      requestedMeeting: null,
      allData: {},
      availableData: null,
      availability: 'Available',
      newDays: {},
      slotTypes: {
        meeting: 'Confirmed',
        pending: 'Pending',
      },
      editLocation: false,
      editMessage: false,
      meetingLocation: '',
      meetingMessage: '',
      agendaTypes: {
        static: 'Static',
        activity: 'Activity',
        panel_session: 'Panel Session',
      },
    };
  },

  methods: {
    ...mapActions({
      fetchMeetings: 'meetingPlanner/fetchMeetings',
      editMeeting: 'meetingPlanner/editMeeting',
      fetchMeetingPlanner: 'meetingPlanner/fetchMeetingPlanner',

    }),
    moment(date) {
      return moment(new Date(date).toUTCString());
    },
    updateDataFromAddEditMembers(upd) {
      this.$emit('input', upd);
      this.$refs['modal-Show'].hide();
    },
    async editInvitation() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;
      const {
        meetingLocation,
        meetingMessage,
      } = this;
      const updateData = {};
      if (meetingLocation !== this.requestedMeeting.invitation_request_details[0].meeting_details.location) updateData.location = meetingLocation;
      if (meetingMessage !== this.requestedMeeting.invitation_request_details[0].meeting_details.message) updateData.message = meetingMessage;
      if (
        this.selectedSlots
          .map(({ id }) => this.meetingPlannerSlots.includes(`${process.env.VUE_APP_API_HOST}/events/meeting-planner-slots/${id}/`))
          .includes(false)
        || this.selectedSlots.length < this.meetingPlannerSlots.length
      ) {
        updateData.meeting_planner_slots = [
          ...new Set([...this.selectedSlots.map(({ id }) => `${process.env.VUE_APP_API_HOST}/events/meeting-planner-slots/${id}/`)]),
        ].sort((a, b) => a - b);
        updateData.invited_customers = this.invitedCustomers;
      }
      if (this.$lodash.isEmpty(updateData)) return;
      updateData.id = this.timeData.invitation_request_details[0].meeting_id;
      updateData.invitor = `${process.env.VUE_APP_API_HOST}/customers/${this.timeData.invitation_request_details[0].requested_by_id}/`;

      try {
        if (isValid) {
          const { data } = await this.editMeeting(updateData);
          await this.fetchMeetingPlanner(this.$route.params.eventId);
          this.$notify({
            group: 'userAlerts',
            title: 'Success',
            text: 'Invitation has been updated',
            type: 'success',
          });
          this.$emit('input', {
            ...this.timeData,
            ...updateData,
            meeting_duration: data.duration,
            ...data.duration,
          });
          await this.resetDurationData(true);
        }
      } catch (err) {
        err.response.data.forEach((message) => {
          this.$notify({
            group: 'userAlerts',
            title: 'Warning',
            text: message,
            type: 'warn',
          });
        });
      }
    },
    async modalShow() {
      this.editLocation = false;
      this.editMessage = false;
      this.$refs['modal-Show'].show();
      this.requestedMeeting = { ...this.value };
      this.meetingLocation = this.requestedMeeting.invitation_request_details[0].meeting_details.location;
      this.meetingMessage = this.requestedMeeting.invitation_request_details[0].meeting_details.message;
    },
    async showModal() {
      this.$refs['modal-Show'].hide();
      const fetchData = {
        event: this.eventUrl,
        opposite_customers: this.invitedCustomers,
        check_customer: true,
      };
      const meetings = await this.fetchMeetings(fetchData);

      this.allData = meetings;
      this.$refs['my-modal'].show();
    },
    handleClose() {
      this.$refs['my-modal'].hide();
      this.$emit('meetingDaysUpdate', this.meetingDaysMap);
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    hide() {
      this.$refs['modal-Show'].hide();
    },
    async hideModalsFromChilds() {
      this.$refs['modal-Show'].hide();
      this.$refs['my-modal'].hide();
      this.resetDurationData(true);
    },
  },
};
</script>


<style src="./SingleMemberInvitation.css" scoped></style>
<style src="./modals.css" scoped></style>
<style src="./StylesForMobile.css" scoped></style>
<style>
@media (max-width: 375px) {
   .editInvitationIcon {
    top: -2px !important;
    right: 0 !important;
  }
  i.editInvitationIcon { 
    right: 3px !important;
    padding: 6px !important;
  }
  #meetingPlanner .fa-pencil-alt {
    top: 11px !important; 
  }
}
.editInvitation > .editInvitationIcon {
  font-size: 11px !important;
  color: #bd9d5f;
  position: absolute;
  padding: 2px;
  top: 15px !important;
  right: 0;
  cursor: pointer;
  z-index: 1;
  transition-duration: 0.2s;
}
.editInvitation > i :hover {
  opacity: 0.2;
}
.editUserInfo {
  padding: 5px 12px;
  border-bottom: 5px solid #ebebeb;
  border-top: 5px solid #ebebeb;
}
.editMultyUserInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3px;
  padding-left: 14px;
}
.editUserInfo .userInfoBox {
  display: grid;
  grid-template-columns: 65px 1fr;
}
.textBox {
  line-height: 16px;
  padding-bottom: 0px;
  padding-top: 1px;
}
.textBox > p {
  margin: 0;
}
.editUserName {
  text-transform: capitalize;
  font-weight: bold;
}
.editUserPosition {
  font-size: 13px;
  text-transform: capitalize;
  color: gray;
}
.editUserCompany {
  font-size: 13px;
  text-transform: capitalize;
  color: #bd9d5f;
}
.modal-body {
  padding: 0 !important;
}
.forStatus {
  padding: 6px 15px 1px 16px;
}
.statusText {
  font-size: 18px;
  color: gray;
}
.statusType {
  color: #5cb85c;
  font-size: 13px;
}
.editMeetingDuration {
  display: flex;
  padding: 0px 0px 6px 14px;
  margin-top: -28px !important;
}
.messageSection {
  margin-top: 4px;
  margin-bottom: 5px;
  padding: 13px;
  border-bottom: 6px solid #ebebeb;
  word-break: break-word;
}
.messageSection > h3 {
  color: gray;
  font-size: 20px;
  font-weight: 300;
}
.messageSection > p {
  color: rgb(41, 41, 41);
  font-size: 15px;
}
.headerClose {
  position: relative;
  height: 2rem;
  width: 100%;
}
.headerClose > button {
  position: absolute;
  right: 0.3rem;
  background: unset;
  border: unset;
  color: #585555;
  font-size: 20px;
  outline: none;
}
.editLocationButton,
.changeDateTime {
  font-size: 13px;
  background: unset;
  color: #bd9d5f;
  height: 24px;
  margin-left: 6px;
  border: 1px solid;
  border-radius: 2px;
}
.changeDateTime {
  padding-bottom: 3px;
}
.editLocationButton:hover,
.changeDateTime:hover {
  background: #bd9d5f;
  color: white;
}
.editLocationButtonDisabled {
  color: #c3c3c3;
  cursor: unset;
  background: unset;
  height: 24px;
  font-size: 13px;
  margin-left: 6px;
  border-radius: 2px;
  border: none;
}
.editLocationMessage {
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
}
.agendaType {
  color: #aaa !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agendaType:hover {
  background-color: #bd9d5f;
  color: #fff !important;
}
.reqMeeting > div {
  grid-template-columns: 45px 1fr;
}
</style>
