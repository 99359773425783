var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticStyle:{"cursor":"pointer"},attrs:{"id":"cancelMeeting"},on:{"click":_vm.showModal}},[_vm._v("\n    Cancel this Meeting\n  ")]),_c('b-modal',{ref:"cancel-meeting-modal",attrs:{"hide-footer":"","body-class":"p-0"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header"},[_c('span',[_vm._v("Are you sure you want to cancel this meeting?")])])]},proxy:true}])},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}}},[_c('div',{staticClass:"date"},[_c('p',{staticClass:"dayMonth"},[_vm._v("\n          "+_vm._s(_vm.moment(_vm.day).format("dddd DD MMMM"))+"\n        ")]),_c('p',{staticClass:"confirmedTime"},[_vm._v("\n          "+_vm._s(_vm.timeData.start.slice(0, 5))+" - "+_vm._s(_vm.timeData.end.slice(0, 5))+"\n        ")]),(_vm.timeData)?_c('div',[_c('h5',{staticClass:"statusText"},[_vm._v("Status")]),(_vm.timeData.slot_type === 'meeting')?_c('p',{staticClass:"statusType"},[_vm._v("\n            confirmed\n          ")]):_vm._e(),(_vm.timeData.slot_type === 'pending')?_c('p',{staticClass:"statusType"},[_vm._v("\n            pending\n          ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"editMultyUserInfo"},_vm._l((_vm.timeData.invitation_request_details),function(invitation){return _c('div',{key:invitation.id,staticClass:"meeting-members"},[_c('div',{staticClass:"imgBox p-0"},[_c('img',{class:!invitation.approved && 'pending-user-img',attrs:{"width":"100%","height":"100%","src":invitation.requested_to_details.profile_picture,"alt":""}}),(!invitation.approved && !invitation.is_proceeded)?_c('i',{staticClass:"far fa-clock pending-user-icon"}):_vm._e(),(!invitation.approved && invitation.is_proceeded)?_c('i',{staticClass:"fas fa-ban declined-user-icon"}):_vm._e()]),_c('div',{staticClass:"textBox"},[_c('router-link',{attrs:{"to":{
                name: 'userProfilePage',
                params: { customerId: invitation.requested_to_id },
              }}},[_c('p',{staticClass:"editUserName"},[_vm._v("\n                "+_vm._s(invitation.requested_to_details.user.first_name)+"\n                "+_vm._s(invitation.requested_to_details.user.last_name)+"\n              ")])]),_c('p',{staticClass:"editUserPosition"},[_vm._v("\n              "+_vm._s(invitation.requested_to_details.job_title)+"\n            ")]),_c('router-link',{attrs:{"to":{
                name: 'companyProfile',
                params: {
                  companyId: invitation.requested_to_details.company_id,
                },
              }}},[_c('p',{staticClass:"editUserCompany mb-0"},[_vm._v("\n                "+_vm._s(invitation.requested_to_details.company.name)+"\n              ")])])],1)])}),0),(_vm.timeData.invitation_request_details.length)?_c('div',{staticClass:"article"},[_c('p',{staticClass:"mt-2 mb-1"},[_vm._v("\n          "+_vm._s(_vm.timeData.invitation_request_details[0].meeting_details.message)+"\n        ")])]):_vm._e(),(_vm.timeData.invitation_request_details.length)?_c('div',{staticClass:"Location"},[_c('p',{staticClass:"meetingLocation"},[_vm._v("Meeting Location:")]),_c('p',{staticClass:"roomMeeting"},[_vm._v("\n          "+_vm._s(_vm.timeData.invitation_request_details[0].meeting_details.location)+"\n        ")])]):_vm._e(),_c('div',{staticClass:"textField"},[_c('p',[_vm._v("\n          Please be kind and send a cancellation message to\n          "),_c('span',{staticClass:"userInfo"},[_vm._v(_vm._s(_vm.timeData.invitation_request_details[0].requested_to_details.user
                .first_name)+"\n            "+_vm._s(_vm.timeData.invitation_request_details[0].requested_to_details.user
                .last_name))])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reason),expression:"reason"},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"name":"Message","maxlength":"300","cols":"50","rows":"5"},domProps:{"value":(_vm.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reason=$event.target.value}}}),(_vm.errors.has('Message'))?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Message")))]):_vm._e()]),_c('div',{staticClass:"cancelModalfooter footer"},[_c('span',{staticClass:"cancelModalNeedsupport"},[_c('a',{staticClass:"privacy-settings-support-text",attrs:{"href":"mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"}},[_vm._v("\n            Need support? Click here!\n          ")])]),_c('button',{staticClass:"confirmButton",on:{"click":function($event){$event.preventDefault();return _vm.sendReq($event)}}},[_vm._v("\n          Cancel This Meeting\n        ")]),_c('button',{staticClass:"confirmButton",on:{"click":_vm.hideModal}},[_vm._v("Back")])])])]),_c('b-modal',{ref:"congrat-canceled-modal",attrs:{"hide-footer":""},on:{"hidden":_vm.hideModal}},[_c('div',{staticClass:"congrat"},[_c('p',[_vm._v("CONGRATULATIONS!")]),_c('p',[_vm._v("Your Meeting request has been successfully canceled.")])]),_c('div',{staticStyle:{"height":"7vh","width":"100%","border-top":"1px solid #eee"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }