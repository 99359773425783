import { mapGetters, mapActions } from 'vuex';
import getIDfromURL from '../getIDfromURL';

export default {
  data() {
    return {
      showEditDurationDiv: false,
      extendableSlots: [],
      selectedSlots: [],
      selectedSlotsIDs: [],
      meetingDaysForMobile: {},
      meetingPlannerSlots: [],
      occupiedSlots: [],
    };
  },
  computed: {
    ...mapGetters({
      mpSlots: 'meetingPlanner/mpSlots',
      isMobileScreen: 'isMobileScreen',
      event: 'events/fetchEvent',
      userInfo: 'user/fetchUserInfo',
      days: 'meetingPlanner/days',
      times: 'meetingPlanner/times',
      meetingDaysMap: 'meetingPlanner/meetingDaysMap',
      availableSlotsModalId: 'meetingPlanner/availableSlotsModalId',
      meetings: 'meetingPlanner/meetings',
    }),
  },
  watch: {
    mpSlots(val) {
      this.meetingDaysForMobile = { ...val };
    },
  },
  methods: {
    ...mapActions({
      fetchMeetingPlanner: 'meetingPlanner/fetchMeetingPlanner',
    }),
    showEditDurationSection(slot) {
      const mpSlots = { ...this.mpSlots };
      const allSlots = [...mpSlots[this.day]];
      const index = allSlots.findIndex(({ id }) => id === slot.id);
      const neededSlots = allSlots.slice(index + 1, index + 4);
      const availableSlots = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const slt of neededSlots) {
        if (slt.invitation_request_details.length) {
          break;
        }
        availableSlots.push(slt);
      }
      this.extendableSlots = availableSlots;
      this.showEditDurationDiv = true;
    },
    async editDuration() {
      if (!this.showEditDurationDiv) {
        const fetchData = {
          event: this.event.url || this.eventUrl,
          opposite_customers: this.selectedUsers ?  this.selectedUsers.map(user => user.details.url) : this.invitedCustomers,
          check_customer: true,
        };
        this.showEditDurationSection(this.value);
        const meetings = await this.fetchMeetings(fetchData);

        for (const slot of this.extendableSlots) {
          const newData = meetings[this.day].find(({ id }) => id === slot.id);
          if (newData.opposite_availability.length) {
            this.occupiedSlots.push({
              id: slot.id,
              message: newData.opposite_availability[0].message,
            });
          }
        }
      } else this.resetDurationData();
    },
    changeMeetingDuration(slot) {
      const occupiedSlot = this.occupiedSlots.find(({ id }) => id === slot.id);
      //  check if slot is in occupiedSlots and then show a toast
      if (occupiedSlot) {
        this.$notify({
          group: 'userAlerts',
          title: 'Duration is not available due to following reason:',
          text: occupiedSlot.message,
          type: 'warn',
        });
        return;
      }

      if (!this.selectedSlots.length) {
        this.selectedSlots.push(this.value);
        this.selectedSlotsIDs.push(this.value.id);
      }
      if (this.selectedSlotsIDs.includes(slot.id)) {
        this.selectedSlotsIDs.sort().pop();
        this.selectedSlots
          .sort((a, b) => getIDfromURL(a.url) - getIDfromURL(b.url))
          .pop();
      } else {
        this.selectedSlots.push(slot);
        this.selectedSlotsIDs.push(slot.id);
      }
    },
    async resetDurationData(update = false) {
      this.selectedSlots = [this.value];
      this.selectedSlotsIDs = [this.value.id];
      this.showEditDurationDiv = false;
      if (update) {
        await this.fetchMeetingPlanner(this.event.id);
      }
      if (this.isMobileScreen) {
        this.handleClose();
      }
    },
    handleClose() {
      this.$bvModal.hide(`available-modal-${this.availableSlotsModalId}`);
      this.confirmModalShow = false;
    },
  },

  mounted() {
    const data = this.timeData || this.requestedMeeting || this.value;
    this.meetingPlannerSlots = data.invitation_request_details.length
      && data.invitation_request_details[0].meeting_planner_slots
      ? data.invitation_request_details[0].meeting_planner_slots.map(
        ({ id }) => `${process.env.VUE_APP_API_HOST}/events/meeting-planner-slots/${id}/`,
      )
      : [
        `${process.env.VUE_APP_API_HOST}/events/meeting-planner-slots/${data.id}/`,
      ];
    this.selectedSlots = this.mpSlots[this.day].filter(({ id }) => this.meetingPlannerSlots.includes(
      `${process.env.VUE_APP_API_HOST}/events/meeting-planner-slots/${id}/`,
    ));

    this.selectedSlotsIDs = this.selectedSlots.map(({ id }) => id);

    const invitations = data.invitation_request_details.filter(
      invitation => invitation.requested_to_id !== this.userInfo.id,
    );
    this.invitedCustomers = invitations.map(
      invitation => `${process.env.VUE_APP_API_HOST}/customers/${invitation.requested_to_id}/`,
    );
  },

};
